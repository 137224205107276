<template>
  <div>
    <Backpage title="terminal.pushChannel">
      <el-table
        border
        :data="channelList"
      >
        <el-table-column
          :label="$t('terminal.streamID')"
          prop="stream_id"
        />
        <el-table-column
          label="IMS IP"
          prop="ims_server_ip"
        />
        <el-table-column
          :label="$t('terminal.br')"
          prop="br"
        />
        <el-table-column
          :label="$t('terminal.resolution')"
          prop="resolution"
        />
        <el-table-column
          :label="$t('addProject.fps')"
          prop="fps"
        />
        <el-table-column
          :label="$t('terminal.pullURL')"
          prop="pull_url"
        />
      </el-table>
    </Backpage>
  </div>
</template>

<script>
import Backpage from '@/components/Backpage.vue'
import { publish_channel_list } from '@/api/terminal.js'
export default {
  components: {
    Backpage
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      total: null,
      channelList: []
    }
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    // 收流
    pullStream () {

    },
    // 修改pageSize
    handleSizeChange (size) {
      this.pageSize = size
      this.getChannelList()
    },
    // 修改当前页码
    handleCurrentChange (current_page) {
      this.currentPage = current_page
      this.getChannelList()
    },
    // 获取推流频道
    async getChannelList () {
      const res = await publish_channel_list({
        terminal_id: Number(this.$route.params.id)
      })
      if (res.data.code === 0) {
        this.channelList = res.data.data
      }
    },
    init () {
      this.getChannelList()
    }
  }
}
</script>

<style lang='scss' scoped></style>
